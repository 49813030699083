import React, { useState, useEffect } from "react";
import "./Auth.css";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

export default function SetNewPassword({ match }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  let reset_token = match.params.token;

  useEffect(() => {
    if (localStorage.getItem("sudha-auth-token")) {
      history.push("/dashboard");
      history.push("/dashboard");
      history.push("/dashboard");
      history.push("/dashboard");
    }
  }, []);

  const handleSetNewPassword = (e) => {
    e.preventDefault();

    if (password != confirmPassword) {
      setError(true);
      setErrorMessage("Passwords Do not match");
      setTimeout(() => {
        setError(false);
      }, 3000);
    } else {
      let data = {
        password: password,
      };
      axios
        .post(`https://api.betarecharge.net/auth/token/${reset_token}`, data)
        .then((res) => {
          console.log(res.data.data);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
              history.push("/login");
          }, 5000);
        })
        .catch((error) => {
          setError(true);
          setErrorMessage("An Error Occoured");
          setTimeout(() => {
            setError(false);
          }, 3000);
          console.log("error is", error);
        });
    }
  };

  return (
    <div id="login-bg" className="fullpage">
      <div className="container-fluid">
        <h2 className="display-4 font-weight-bold text-white pt-3 pl-2">
          Sudha'
        </h2>
        <div className="row">
          <div className="col-md-7"></div>
          <div className="col-md-5">
            <h1 className="text-right mr-5 font-weight-bold mb-5 text-white">
              SET NEW PASSWORD!
            </h1>
            <form onSubmit={handleSetNewPassword}>
              {error && (
                <div className="alert alert-danger">{errorMessage}</div>
              )}
              {success && (
                <div className="alert alert-success">
                  New Password set Successfully ...{" "}
                </div>
              )}
              <div className="form-group mt-3">
                <label
                  className="text-white lead font-weight-bold"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  required
                  type="password"
                  className="form-control p-4"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="form-group mt-3">
                <label
                  className="text-white lead font-weight-bold"
                  htmlFor="confirmPassword"
                >
                  Confirm Password
                </label>
                <input
                  required
                  type="password"
                  className="form-control p-4"
                  id="confirmPassword"
                  placeholder="Retype Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <div className="justify-content-end d-flex mt-5">
                <button
                  type="submit"
                  className="btn btn-primary rounded px-5 py-3 bg-orange font-weight-bolder"
                  style={{ fontSize: "30px" }}
                >
                  Set New Password !
                </button>
              </div>
            </form>
            <div className="text-white text-right font-weight-bold mt-5">
              <p>
                click here to{" "}
                <span className="btn btn-primary bg-orange px-4 ">
                  <Link className="text-white" to="/login">
                    login
                  </Link>
                </span>{" "}
                or{" "}
                <span className="btn btn-primary bg-orange px-4 ">
                  <Link className="text-white" to="/register">
                    register
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className=" ">
          <div className="container">
            <h2 className="purple">
              Not Registered ?{" "}
              <span className="orange font-weight-bold">
                <Link className={"orange"} to="/register">
                  SIGNUP
                </Link>
              </span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}
