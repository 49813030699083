import React, { useState, useEffect } from "react";
import "./Auth.css";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("sudha-auth-token")) {
      history.push("/dashboard");
      history.push("/dashboard");
      history.push("/dashboard");
      history.push("/dashboard");
    }
  }, [])

  const handleLogin = (e) => {
    e.preventDefault();
    let data = {
      email: email,
      password: password,
    };
    axios
      .post(`https://api.betarecharge.net/auth/login/`, data)
      .then((res) => {
        localStorage.setItem("sudha-auth-token", res.data.data["auth-token"]);
        localStorage.setItem("sudha_user_name", res.data.data.user.name);
        localStorage.setItem("sudha_user_email", res.data.data.user.email);
        localStorage.setItem(
          "sudha_user_phone",
          res.data.data.user.phoneNumber
        );
        localStorage.setItem(
          "sudha_user_creation",
          res.data.data.user.createdAt
        );
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          history.push("/dashboard");
        }, 3000);
      })
      .catch((error) => {
        setError(true);
        setErrorMessage("Invalid Login Credentials");
        setTimeout(() => {
          setError(false);
        }, 3000);
        console.log("error is", error);
      });
  };

  return (
    <div id="login-bg" className="fullpage">
      <div className="container-fluid">
        <h2 className="display-4 font-weight-bold text-white pt-3 pl-2">
          Sudha'
        </h2>
        <div className="row">
          <div className="col-md-7"></div>
          <div className="col-md-5">
            <h1 className="text-right mr-5 font-weight-bold mb-5 text-white">
              WELCOME BACK!
            </h1>
            <form onSubmit={handleLogin}>
              {error && (
                <div className="alert alert-danger">{errorMessage}</div>
              )}
              {success && (
                <div className="alert alert-success">Login Successful ... </div>
              )}
              <div className="form-group">
                <input
                  type="email"
                  className="form-control p-4"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Email Or Phone Number"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group mt-5">
                <input
                  type="password"
                  className="form-control p-4"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="justify-content-end d-flex mt-5">
                <button
                  type="submit"
                  className="btn btn-primary rounded px-5 py-3 bg-orange font-weight-bolder"
                  style={{ fontSize: "30px" }}
                >
                  LOGIN
                </button>
              </div>
            </form>
            <div className="text-white text-right font-weight-bold mt-5">
              <p>
                Forgot Your password ?
                <span className="btn btn-primary bg-orange px-4 ">
                  <Link className="text-white" to="/reset-password">
                    forgot password
                  </Link>
                </span>{" "}
                or{" "}
                <span className="btn btn-primary bg-orange px-4 ">
                  <Link className="text-white" to="/register">
                    register
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className=" ">
          <div className="container">
            <h2 className="purple">
              Not Registered ?{" "}
              <span className="orange font-weight-bold"><Link className={"orange"} to="/register">SIGNUP</Link></span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}
