import React,{useState} from "react";
import { Link, useHistory } from "react-router-dom";
import "./Sidebar.css";
import { Modal, Table, notification } from "antd";

export default function Sidebar({ data, mySidebar ,openSidebar}) {
  const [visible, setVisible] = useState(false);

  const history = useHistory();
  const handleLogout = () => {
    localStorage.removeItem("sudha-auth-token");
    localStorage.removeItem("sudha_user_name");
    localStorage.removeItem("sudha_user_email");
    localStorage.removeItem("sudha_user_phone");
    localStorage.removeItem("sudha_user_creation");
    history.push("/");
  };
  return (
    <div className="bg-light-purple " >
      <Modal
          title="Logout"
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <div className="">
            <h6 className="font-weight-bold">Are you sure You want to Logout ??</h6>
            <div className="text-right">
              <button className="btn btn-danger btn-sm" onClick={handleLogout}> Logout</button>
            </div>
          </div>
        </Modal>
      <Link to="/">
        <h1 className="ml-4 text-white font-weight-bolder pt-2">Sudha'</h1>
      </Link>
      <br />
      <br />
      <div className="py-3">
      {data.map((item, index) =>
        item.path === "/logout" ? (
          <div className="mt-2">
            <a
              className={
                item.active
                  ? "text-left bg-cream px-2 py-4  curve-left ml-4 purple text-capitalize lead side-text"
                  : "text-left text-capitalize px-2 py- ml-4 text-white lead side-text"
              }
              onClick={() => setVisible(true)}
            >
              <i className={item.icon}></i> {item.name}
            </a>
          </div>
        ) : (
          <Link key={index} to={`${item.path}`}>
            <div
              className={
                item.active
                  ? "text-left bg-cream px-2 py-3  curve-left ml-4 purple text-capitalize lead side-text"
                  : "text-left text-capitalize px-2 py-3 ml-4 text-white lead side-text"
              }
            >
              <i className={item.icon}></i> {item.name}
            </div>
          </Link>
        )
      )}
      </div>
    </div>
  );
}
