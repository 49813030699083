import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import { Modal, notification } from "antd";
import Moment from "moment";
import SweetAlert from "sweetalert2-react";

export default function Settings() {
  const sidebarData = [
    {
      name: "dashboard",
      active: false,
      icon: "fa fa-home",
      path: "/dashboard",
    },
    {
      name: "Add Funds",
      active: false,
      icon: "fa fa-credit-card pr-2",
      path: "/add-funds",
    },
    {
      name: "Contact",
      active: false,
      icon: "fa fa-user pr-2",
      path: "/contacts",
    },
    {
      name: "Airtime",
      active: false,
      icon: "fa fa-phone pr-2",
      path: "/airtime",
    },
    {
      name: "Data",
      active: false,
      icon: "fa fa-volume-control-phone pr-2",
      path: "/data",
    },
    {
      name: "History",
      active: false,
      icon: "fa fa-clock-o pr-2",
      path: "/history",
    },
    {
      name: "Settings",
      active: true,
      icon: "fa fa-gear pr-2",
      path: "/settings",
    },
    {
      name: "Logout",
      active: false,
      icon: "fa fa-sign-out pr-2",
      path: "/logout",
    },
  ];
  const [avatar, setAvatar] = useState(
    localStorage.getItem("sudha_user_avatar")
  );
  const [viewAvatar, setViewAvatar] = useState(avatar);
  const [email, setEmail] = useState(localStorage.getItem("sudha_user_email"));
  const [name, setName] = useState(localStorage.getItem("sudha_user_name"));
  const [apiPassword, setAPIPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertDetails, setAlertDetails] = useState([]);

  let joined = localStorage.getItem("sudha_user_creation");
  let formated_join = Moment(joined).format("YYYY-MM-DD");

  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    let data = {
      name: name,
      email: email,
    };
    axios
      .put("https://api.betarecharge.net/settings/bio", data, {
        headers: {
          "auth-token": localStorage.getItem("sudha-auth-token"),
        },
      })
      .then((response) => {
        console.log(response);
        localStorage.setItem("sudha_user_name", response.data.data.name);
        setName(response.data.data.name);
        setAlertDetails([
          "success",
          "Profile Updated Successfully",
          "Profile Details has been successfully Updated ..please refresh"
        ]);
        setSuccess(true)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showAlert = () => {
    return (
      <SweetAlert
        type={alertDetails[0]}
        confirmBtnText="Cool"
        show={success}
        title={alertDetails[1]}
        text={alertDetails[2]}
        onConfirm={() => setSuccess(false)}
      />
    );
  };

  const handleAPIPasswordUpdate = (e) => {
    e.preventDefault();
    let data = {
      password: apiPassword,
    };
    axios
      .post("https://api.betarecharge.net/api/v1/register", data, {
        headers: {
          "auth-token": localStorage.getItem("sudha-auth-token"),
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setAlertDetails([
          "success",
          "API Password updated Successfully",
          "API Password has been successfully updated"
        ]);
        setSuccess(true)
      })
      .catch((error) => {
        console.log(error);
        setAlertDetails([
          "error",
          "API Password couldnt be updated",
          "API Password update failed"
        ]);
        setSuccess(true)
      });
    setAPIPassword("");
    setVisible(false);
  };

  return (
    <div>
      <Layout sidedata={sidebarData}>
        {success && showAlert()}
        <Modal
          title="Update API Password"
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <form>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                aria-describedby="helpId"
                placeholder="Update API Password"
                value={apiPassword}
                onChange={(e) => setAPIPassword(e.target.value)}
              />
              <small id="helpId" className="form-text text-muted">
                you are about to change your API key....
              </small>
            </div>

            <div className="text-right">
              <button
                type="button"
                className="btn btn-primary px-4 bg-orange"
                onClick={handleAPIPasswordUpdate}
              >
                UPDATE
              </button>
            </div>
          </form>
        </Modal>
        <div className=" bg-cream full-height">
          <div className="justify-content-between d-flex">
            <div className="">
              <h2 className="font-weight-bold orange pt-1">Settings</h2>
              <p>update your profile...</p>
            </div>
            <div className="">
              <i className="fa fa-bell fa-2x px-2 "></i>
              <i className="fa fa-comment fa-2x p-2"></i>
              <img
                src={localStorage.getItem("sudha_user_avatar")}
                className=" rounded-circle px-2"
                height="40px"
                alt=""
              />
            </div>
          </div>
          <div className="content">
            <div className="container bootstrap snippet">
              <div className="row">
                <div className="col-sm-10">
                  <h2 className="text-capitalize font-weight-bold orange">
                    Hello {localStorage.getItem("sudha_user_name")} !
                  </h2>
                </div>
                {/* <div className="col-sm-2">
                  <a href="/users" className="pull-right">
                    <img
                      title="profile image"
                      className="img-circle img-responsive"
                      src="http://www.gravatar.com/avatar/28fd20ccec6865e2d5f0e1f4446eb7bf?s=100"
                    />
                  </a>
                </div> */}
              </div>
              <div className="row">
                <div className="col-sm-3">
                  {/*left col*/}
                  <div className="text-left">
                    <img
                      src={
                        viewAvatar
                          ? viewAvatar
                          : "http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
                      }
                      className="avatar rounded-circle img-thumbnail mb-4"
                      alt="avatar"
                    />
                    <br />
                    <h6>Upload a different photo...</h6>
                    <input
                      type="file"
                      className="text-center center-block file-upload"
                      onChange={(event) => {
                        setViewAvatar(
                          URL.createObjectURL(event.target.files[0])
                        );
                        setAvatar(event.target.files[0]);
                        let data = new FormData();
                        data.append("file", event.target.files[0]);
                        console.log(event.target.files[0]);
                        axios.put(
                          "https://api.betarecharge.net/settings/avatar",
                          data,
                          {
                            headers: {
                              "Content-Type": "multipart/form-data",
                              "auth-token": localStorage.getItem(
                                "sudha-auth-token"
                              ),
                            },
                          }
                        );
                        setAlertDetails([
                          "success",
                          "Avatar Successfully",
                          "Avatar has been successfully Updated"
                        ]);
                        setSuccess(true)
                      }}
                    />
                  </div>
                  <br />
                  <br />

                  <ul className="list-group">
                    <li className="list-group-item text-muted">
                      <span className="pull-left font-weight-bold">
                        Actions
                      </span>
                      <i className="fa fa-dashboard fa-1x pull-right" />
                    </li>
                    <li className="list-group-item ">
                      <button
                        className="btn btn-primary py-2 px-2 bg-orange btn-block "
                        onClick={() => setVisible(true)}
                      >
                        Set API Password
                      </button>
                    </li>
                  </ul>
                  <br />
                  <br />
                  <br />
                  <div className="panel panel-default">
                    <div className="panel-heading">Social Media</div>
                    <div className="panel-body">
                      <i className="fa fa-facebook fa-2x" />{" "}
                      <i className="fa fa-github fa-2x" />{" "}
                      <i className="fa fa-twitter fa-2x" />{" "}
                      <i className="fa fa-pinterest fa-2x" />{" "}
                      <i className="fa fa-google-plus fa-2x" />
                    </div>
                  </div>
                </div>
                {/*/col-3*/}
                <div className="col-md-9">
                  <div className="tab-content">
                    <div className="tab-pane active" id="home">
                      <br />
                      <form onSubmit={handleUpdateProfile}>
                        <div>
                          <div className="form-row">
                            <div className="col">
                              <label htmlFor="">Name</label>

                              <input
                                type="text"
                                className="form-control text-capitalize"
                                placeholder="Full name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                            <div className="col">
                              <label htmlFor="">Email</label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Last name"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <br />
                          <div className="form-row">
                            <div className="col">
                              <label htmlFor="">API KEY</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="API key"
                                readOnly={true}
                              />
                            </div>
                            <div className="col">
                              <label htmlFor="">Date Joined</label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder="Date Joined"
                                readOnly={true}
                                value={formated_join}
                              />
                            </div>
                          </div>
                          <br />
                          <div className="text-right">
                            <button
                              className="btn btn-primary bg-orange"
                              type="submit"
                            >
                              Update Profile
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/*/tab-pane*/}
                </div>
                {/*/tab-content*/}
              </div>
              {/*/col-9*/}
            </div>
            {/*/row*/}
          </div>
        </div>
      </Layout>
    </div>
  );
}
