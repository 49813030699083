import React from "react";

export default function NotFound() {
  return (
    <div className="mt-5 text-center bg-cream full-width">
      <h1 className="display-1">404</h1>
      <h1 className="">Oh Oh! .... Page Not Found</h1>
      <button className="btn btn-primary bg-orange mt-4">Go Back</button>
    </div>
  );
}
