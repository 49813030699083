import React, { useState, useEffect } from "react";
import "./Auth.css";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("sudha-auth-token")) {
      history.push("/dashboard");
      history.push("/dashboard");
      history.push("/dashboard");
      history.push("/dashboard");
    }
  }, [])

  const handleResetPassword = (e) => {
    e.preventDefault();
    let data = {
      email: email,
    };
    axios
      .post(`https://api.betarecharge.net/auth/reset/`, data)
      .then((res) => {
        console.log(res.data.data)
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      })
      .catch((error) => {
        setError(true);
        setErrorMessage("An Error Occoured ");
        setTimeout(() => {
          setError(false);
        }, 3000);
        console.log("error is", error);
      });
  };

  return (
    <div id="login-bg" className="fullpage">
      <div className="container-fluid">
        <h2 className="display-4 font-weight-bold text-white pt-3 pl-2">
          Sudha'
        </h2>
        <div className="row">
          <div className="col-md-7"></div>
          <div className="col-md-5">
            <h1 className="text-right mr-5 font-weight-bold mb-5 text-white">
              RESET YOUR PASSWORD!
            </h1>
            <form onSubmit={handleResetPassword}>
              {error && (
                <div className="alert alert-danger">{errorMessage}</div>
              )}
              {success && (
                <div className="alert alert-success">Password Reset Email Sent Successfuly , check your email for confirmation ... </div>
              )}
              <div className="form-group">
                  <label className="text-white lead font-weight-bold" htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control p-4"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="justify-content-start d-flex mt-5">
                <button
                  type="submit"
                  className="btn btn-primary rounded px-5 py-3 bg-orange font-weight-bolder"
                  style={{ fontSize: "30px" }}
                >
                  RESET PASSWORD
                </button>
              </div>
              <div className="text-white font-weight-bold mt-5 text-right">
                <p>click here to <span className="btn btn-primary bg-orange px-4 "><Link className="text-white" to="/login">login</Link></span> or <span className="btn btn-primary bg-orange px-4 "><Link className="text-white" to="/register">register</Link></span></p>
              </div>
            </form>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        
      </div>
    </div>
  );
}
