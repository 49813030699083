import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import "./Dashboard.css";
import Moment from "moment";
import { Modal, notification } from "antd";
import axios from "axios";
import { Line } from "react-chartjs-2";
import { Link, useHistory } from "react-router-dom";
import SweetAlert from "sweetalert2-react";

export default function Dashboard() {
  const sidebarData = [
    {
      name: "dashboard",
      active: true,
      icon: "fa fa-home",
      path: "/dashboard",
    },
    {
      name: "Add Funds",
      active: false,
      icon: "fa fa-credit-card pr-2",
      path: "/add-funds",
    },
    {
      name: "Contact",
      active: false,
      icon: "fa fa-user pr-2",
      path: "/contacts",
    },
    {
      name: "Airtime",
      active: false,
      icon: "fa fa-phone pr-2",
      path: "/airtime",
    },
    {
      name: "Data",
      active: false,
      icon: "fa fa-volume-control-phone pr-2",
      path: "/data",
    },
    {
      name: "History",
      active: false,
      icon: "fa fa-clock-o pr-2",
      path: "/history",
    },
    {
      name: "Settings",
      active: false,
      icon: "fa fa-gear pr-2",
      path: "/settings",
    },
    {
      name: "Logout",
      active: false,
      icon: "fa fa-sign-out pr-2",
      path: "/logout",
    },
  ];

  const [balance, setBalance] = useState(0);
  const [commissionBalance, setCommissionBalance] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertDetails, setAlertDetails] = useState([]);
  const [months, setMonths] = useState([]);
  const [topupData, setTopupData] = useState([]);
  const [airtimeData, setAirtimeData] = useState([]);

  // const [showLastTransaction, setShowLastTransaction] = useState(false);
  const [avatar, setavatar] = useState("");
  const history = useHistory();
  const [transactionCount, settransactionCount] = useState(0);
  const [contactsCount, setcontactsCount] = useState(0);
  const [contactsGroupCount, setcontactsGroupCount] = useState(0);

  const headers = {
    "auth-token": localStorage.getItem("sudha-auth-token"),
  };

  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };
  let name = localStorage.getItem("sudha_user_name");
  let email = localStorage.getItem("sudha_user_email");
  let phone = localStorage.getItem("sudha_user_phone");
  let joined = localStorage.getItem("sudha_user_creation");
  let formated_join = Moment(joined).format("YYYY-MM-DD");

  const getBalance = () => {
    axios
      .get(`https://api.betarecharge.net/wallet/balance`, { headers })
      .then((response) => {
        console.log(response.data);
        setBalance(response.data.data.amount);
        setCommissionBalance(response.data.data.commissionBalance);
        settransactionCount(response.data.data.transactionsCount);
        setcontactsCount(response.data.data.contactsCount);
        setcontactsGroupCount(response.data.data.contactGroupsCount);
        // if (response.data.data.last_transaction) {
        //   setShowLastTransaction(true);
        //   setlastTransactionTitle(
        //     response.data.data.last_transaction.creditMeans
        //   );
        //   setlastTransactionDate(response.data.data.last_transaction.createdAt);
        //   setlastTransactionBalance(response.data.data.last_transaction.amount);
        //   // setLoading(false);
        // }
        // openNotificationWithIcon(
        //   "success",
        //   "Balance Fetched Successfully",
        //   "Balance has been successfully fetched"
        // );
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          localStorage.removeItem("sudha-auth-token");
          history.push("/login");
        } else {
          setTimeout(() => {
            setLoading(false);
            openNotificationWithIcon(
              "error",
              "Balance couldnt be fetched",
              "Balance fetching failed"
            );
          }, 2000);
        }
      });
  };
  const showAlert = () => {
    return (
      <SweetAlert
        type={alertDetails[0]}
        confirmBtnText="Cool"
        show={success}
        title={alertDetails[1]}
        text={alertDetails[2]}
        onConfirm={() => setSuccess(false)}
      />
    );
  };

  const getAvatar = () => {
    axios
      .get(`https://api.betarecharge.net/settings/avatar`, { headers })
      .then((response) => {
        console.log(response.data);
        setavatar(response.data.data);
        localStorage.setItem("sudha_user_avatar", response.data.data);
      })
      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
    axios
      .get(`https://api.betarecharge.net/wallet/chartData/`, { headers })
      .then((response) => {
        console.log(response);
        setMonths(response.data.data.months);
        setTopupData(response.data.data.walletTransactions);
        setAirtimeData(response.data.data.debitTransactions);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  };

  const moveCommission = () => {
    axios
      .get(`https://api.betarecharge.net/wallet/moveCommissionBalance`, { headers })
      .then((response) => {
        console.log(response.data);
        setAlertDetails([
          "success",
          "Main Balance Updated",
          response.data.message,
          
        ]);
        setSuccess(true);
        setLoadMore(!loadMore)
      })
      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          setLoading(false);
          setAlertDetails([
            "error",
            "An Error Occoured",
            error.response.data.message,
          ]);
          setSuccess(true);
        }, 2000);
      });
  };

  useEffect(() => {
    setLoading(true);
    getBalance();
    getAvatar();
  }, [loadMore]);

  const data = {
    labels: months,
    datasets: [
      {
        label: "Wallet Transactions",
        data: topupData,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
      {
        label: " Airtime/Data Transactions",
        data: airtimeData,
        fill: false,
        borderColor: "#742774",
      },
    ],
  };

  const options = {
    backgroundColor: "rgba(75,192,192,0.2)",
  };

  return (
    <div>
      <Layout sidedata={sidebarData}>
      {success && showAlert()}
        <div className="row bg-cream">
          <div className="col-md-9 mt-2 ">
            <div className="justify-content-between d-flex">
              <div className="">
                <h2 className="orange font-weight-bold">
                  Hello, <span className="text-capitalize">{name}</span>
                </h2>
              </div>
              <div className="">
                <i className="fa fa-bell fa-2x"></i>
                <i className="fa fa-comment fa-2x"></i>
              </div>
            </div>

            <p className="text-lead">Welcome back!</p>
            <div className="chart-section">
              <Line data={data} options={options} />
              {/* <p className="text-center">
                    chart goes here...
                </p> */}
            </div>
            <div className="cards-section">
              <br />
              <div className="row">
                <div className="col-md-6 text-white   text-white font-weight-bold">
                  <div className="bg-light-purple text-white p-4 rounded">
                    <h4 className="text-white">
                      <i className="fa fa-credit-card text-white"></i> Wallet
                      Balance
                    </h4>
                    <h4 className="display-4 font-weight-bold text-white">
                      N {balance.toFixed(2)}
                    </h4>
                  </div>
                </div>
                <div className="small m-2"></div>
                <div className="col-md-6 text-white   text-white font-weight-bold">
                  <div className="bg-light-purple text-white p-4 rounded">
                    <h4 className="text-white">
                      <i className="fa fa-credit-card text-white"></i>{" "}
                      Commission Balance
                    </h4>
                    <h4 className="display-4 font-weight-bold text-white">
                      N {commissionBalance.toFixed(2)}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 vertical-line">
            <div className="text-center px-4 ">
              <div className="mt-5 small" style={{ paddingTop: "160px" }}></div>
              <img
                src={avatar}
                alt="avatar"
                className="rounded-circle img-fluid px-5 mt-4"
              />
              <h4 className="font-weight-bold mt-3">{phone}</h4>
              {/* <p className="">{email}</p> */}
              <em>member since {formated_join}</em>
              <h5 className="purple mt-2">Retailer</h5>
              <div className="justify-content-between d-flex border border-dark p-2 rounded font-weight-bold">
                <div className="icon pt-3">
                  <i className="fa fa-line-chart fa-2x"></i>
                </div>
                <div className="text-right">
                  <p>{transactionCount}</p>
                  <p>Transactions</p>
                </div>
              </div>
              <div className="justify-content-between d-flex border border-dark p-2 rounded font-weight-bold mt-2">
                <div className="icon pt-3">
                  <i className="fa fa-users fa-2x"></i>
                </div>
                <div className="text-right">
                  <p>{contactsGroupCount}</p>
                  <p>Groups</p>
                </div>
              </div>
              <div className="justify-content-between d-flex border border-dark p-2 rounded font-weight-bold mt-2">
                <div className="icon pt-3">
                  <i className="fa fa-user fa-2x"></i>
                </div>
                <div className="text-right">
                  <p>{contactsCount}</p>
                  <p>Contacts</p>
                </div>
              </div>
              <div className=" mt-3 text-white font-weight-bold ">
                <div
                  className="bg-orange text-white p-2 rounded payment"
                  style={{ cursor: "pointer" }}
                >
                  <h4 className="text-white">
                    <i className="fa fa-clock-o"></i> View All Transactions
                  </h4>
                </div>
                <div
                  className="bg-orange text-white p-2 mt-2 rounded payment"
                  onClick={moveCommission}
                  style={{ cursor: "pointer" }}
                >
                  <h4 className="text-white"> Move Commission</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
