import React, { useState, useRef } from "react";
import Layout from "../../components/Layout/Layout";
import "./AddFunds.css";
import { useFlutterwave } from "flutterwave-react-v3";
import { PaystackButton, usePaystackPayment } from "react-paystack";
import { Modal, notification } from "antd";
import axios from "axios";
import SweetAlert from "sweetalert2-react";


export default function AddFunds() {
  const sidebarData = [
    {
      name: "dashboard",
      active: false,
      icon: "fa fa-home",
      path: "/dashboard",
    },
    {
      name: "Add Funds",
      active: true,
      icon: "fa fa-credit-card pr-2",
      path: "/add-funds",
    },
    {
      name: "Contact",
      active: false,
      icon: "fa fa-user pr-2",
      path: "/contacts",
    },
    {
      name: "Airtime",
      active: false,
      icon: "fa fa-phone pr-2",
      path: "/airtime",
    },
    {
      name: "Data",
      active: false,
      icon: "fa fa-volume-control-phone pr-2",
      path: "/data",
    },
    {
      name: "History",
      active: false,
      icon: "fa fa-clock-o pr-2",
      path: "/history",
    },
    {
      name: "Settings",
      active: false,
      icon: "fa fa-gear pr-2",
      path: "/settings",
    },
    {
      name: "Logout",
      active: false,
      icon: "fa fa-sign-out pr-2",
      path: "/logout",
    },
  ];
  const publicKey = "pk_live_ebf6ff0aa05fb8e93959918179112388f7eee645";
  const [amount, setAmount] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [paystackVisible, setpaystackVisible] = useState(false);
  const [flutterwaveVisible, setflutterwaveVisible] = useState(false);
  const [reciept, setReciept] = useState("");
  const file_ref = useRef();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertDetails, setAlertDetails] = useState([]);

  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  const convertToKobo = (amount) => {
    let full = amount.toString() + "00";
    return parseInt(full);
  };

  const componentProps = {
    email,
    amount: convertToKobo(amount),
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "Pay With Paystack",
    onSuccess: (res) =>{
      console.log(res.reference)
      console.log(res.message)
      console.log(res.status)
      handleTopup();
      clearData();
      setpaystackVisible(false)
    },
    onClose: (res) =>{
      console.log(res)
      setTimeout(() => {
        setAlertDetails([
          "error",
          "Topup was not completed",
          "Topup operation failed"
        ]);
        setSuccess(true)
      }, 1000);
      // handleTopup();
      // clearData();
      // setpaystackVisible(false)
    }
    
  };


  const config = {
    public_key: "FLWPUBK_TEST-1efe8f4188e76e545a9e8a715eb008f1-X",
    tx_ref: Date.now(),
    amount: amount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: email,
      phonenumber: phone,
      name: name,
    },
    customizations: {
      title: "Sudha Data Payment",
      description: "Payment for airtime and data purchase",
      logo: "https://i.ibb.co/XYXNZ25/logo.png",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);
  const handlePaystackPayment = usePaystackPayment(componentProps);

  const clearData = () => {
    setAmount("");
    setEmail("");
    setPhone("");
    setName("");
  };

  const headers = {
    "auth-token": localStorage.getItem("sudha-auth-token"),
  };

  const handleTopup = () => {
    let data = {
      name: name,
      email: email,
      amount: amount,
      network: phone,
    };
    axios
      .post(`https://api.betarecharge.net/wallet/topup`, data, { headers })
      .then((res) => {
        console.log(res.data.message);
        setTimeout(() => {
          setAlertDetails([
            "success",
            "Topup made Successfully",
            "topup has been made successfully"
          ]);
          setSuccess(true)
          clearData();
        }, 1000);
      })
      .catch((error) => {
        setTimeout(() => {
          setAlertDetails([
            "error",
            "Topup was not completed",
            "Topup operation failed"
          ]);
          setSuccess(true)
        }, 1000);
        clearData();
      });
  };

  const handleClearFile = () => {
    file_ref.current.value = "";
    setReciept(null);
  };

  const showAlert = () => {
    return (
      <SweetAlert
        type={alertDetails[0]}
        confirmBtnText="Cool"
        show={success}
        title={alertDetails[1]}
        text={alertDetails[2]}
        onConfirm={() => setSuccess(false)}
      />
    );
  };

  const upload_payment_proof = () => {
    let data = new FormData();
    data.append("file", reciept);
    console.log(reciept);
    axios
      .post("https://api.betarecharge.net/wallet/upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("sudha-auth-token"),
        },
      })
      .then((response) => {
        console.log(response);
        setAlertDetails([
          "success",
          "Reciept Successfully",
          "Reciept has been successfully Uploaded"
        ]);
        setSuccess(true)
      })
      .catch((error) => {
        console.log(error.response);
        openNotificationWithIcon(
          "error",
          "Reciept Upload Failed",
          error.response.data.message
        );
      });
    handleClearFile();
  };

  return (
    <div>
      <Layout sidedata={sidebarData}>
      {success && showAlert()}
        <div className=" bg-cream full-height">
          <div className="justify-content-between d-flex">
            <div className="">
              <h3 className="font-weight-bold orange pt-1">Add Funds</h3>
              <p>Top-Up your wallet</p>
            </div>
            <div className="">
              <i className="fa fa-bell fa-2x px-2 "></i>
              <i className="fa fa-comment fa-2x p-2"></i>
              <img
                src={localStorage.getItem("sudha_user_avatar")}
                className=" rounded-circle px-2"
                height="40px"
                alt=""
              />
            </div>
          </div>
          <h4>
            <em>How Would You Like To Pay ?</em>
          </h4>
          <div className="row mt-5">
            <div className="col-md-3">
              <div class="card payment border-info  ">
                <div class="card- px-2 py-3">
                  <h2>
                    <i class="fa fa-bank pr-3" style={{ fontSize: "25px" }}></i>Bank
                    Transfer
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-1 m-2"></div>
            <div className="col-md-3">
              <div
                class="card border border-info purple payment"
                onClick={() => setpaystackVisible(true)}
              >
                <div class="card- px-0 py-2">
                  <div className="row py-0">
                    <div className="col-3 ">
                      <img
                        className="img-fluid"
                        src="./paystack.png"
                        alt=""
                      />
                    </div>
                    <div className="col-9">
                      <h2 className=""> Paystack</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>

            {/* <div className="col-3">
              <div
                class="card border border-info purple payment"
                onClick={() => setflutterwaveVisible(true)}
              >
                <div class="card-body">
                  <div className="row">
                    <div className="col pt-3">
                      <img
                        height={"15px"}
                        className="img-fluid"
                        src="./flutterwave.png"
                        alt=""
                      />
                    </div>
                    <div className="col  py-0">
                      <h2> Flutterwave</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <br />
          <br />
          <div className="">
            <ol>
              <li>
                <h3>TIG COMMUNICATIONS LTD</h3>
                <p>0001939345</p>
                <p>ACCESS BANK</p>
              </li>
              <br />
              <li>
                <form action="">
                  <div className="form-group">
                    <label htmlFor />
                    <input
                      type="file"
                      className="form-control-file"
                      accept="image/png, image/jpeg, application/pdf"
                      ref={file_ref}
                      onChange={(event) => setReciept(event.target.files[0])}
                      aria-describedby="fileHelpId"
                    />
                    <small id="fileHelpId" className="form-text text-muted">
                      upload payment Document Here
                    </small>
                  </div>
                </form>
              </li>
              <br />
              <li>
                <em>Completed The Transfer ?</em>
              </li>
              <br />
              <button
                className="btn btn-info px-4"
                onClick={upload_payment_proof}
              >
                Notify Admin
              </button>
            </ol>

            <div className="text-right pr-4">
              <button
                className="btn btn-primary px-4 py-3"
                onClick={handleClearFile}
              >
                Done
              </button>
            </div>
          </div>
        </div>
        <br />

        {/* paystack modal */}
        <Modal
          title="Wallet Topup Via  Paystack"
          visible={paystackVisible}
          onOk={() => setpaystackVisible(false)}
          onCancel={() => setpaystackVisible(false)}
          footer={null}
        >
          <form onSubmit={(e) => {
              e.preventDefault();
              handlePaystackPayment(componentProps.onSuccess,componentProps.onClose);
            }}>
            <div>
              <h2>
                Wallet Topup <i className="fa fa-credit-card"></i>
              </h2>
              <div>
                <div className="row">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Full Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
              <br />

              <div
                className="text-right"
                // onClick={() => setpaystackVisible(false)}
              >
                <button
                  className="btn btn-primary px-4 bg-orange"
                  type="submit"
                >
                  Pay With Paystack
                </button>
                {/* <PaystackButton
                  {...componentProps}
                  className="btn btn-primary px-4 bg-orange"
                  type="submit"
                ></PaystackButton> */}
              </div>
            </div>
          </form>
        </Modal>
        {/* flutterwave modal */}
        <Modal
          title="Wallet Topup Via  Flutterwave"
          visible={flutterwaveVisible}
          onOk={() => setflutterwaveVisible(false)}
          onCancel={() => setflutterwaveVisible(false)}
          footer={null}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleFlutterPayment({
                callback: (response) => {
                  console.log(response);
                  handleTopup();
                  clearData();
                  
                },
                onClose: () => {
                  handleTopup();
                  clearData();
                },
              });
            }}
          >
            <div>
              <h2>
                Wallet Topup <i className="fa fa-credit-card"></i>
              </h2>
              <div>
                <div className="row">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Full Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
              <br />

              <div className="text-right">
                <button
                  className="btn btn-primary px-4 bg-orange"
                  type="submit"
                >
                  Pay With Flutterwave
                </button>
              </div>
            </div>
          </form>
        </Modal>
      </Layout>
    </div>
  );
}
