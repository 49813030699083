import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import axios from "axios";
import { Modal, Table, notification } from "antd";
import Moment from "moment";
import SweetAlert from "sweetalert2-react";
import { Link, useHistory } from "react-router-dom";

export default function Data() {
  const sidebarData = [
    {
      name: "dashboard",
      active: false,
      icon: "fa fa-home",
      path: "/dashboard",
    },
    {
      name: "Add Funds",
      active: false,
      icon: "fa fa-credit-card pr-2",
      path: "/add-funds",
    },
    {
      name: "Contact",
      active: false,
      icon: "fa fa-user pr-2",
      path: "/contacts",
    },
    {
      name: "Airtime",
      active: false,
      icon: "fa fa-phone pr-2",
      path: "/airtime",
    },
    {
      name: "Data",
      active: true,
      icon: "fa fa-volume-control-phone pr-2",
      path: "/data",
    },
    {
      name: "History",
      active: false,
      icon: "fa fa-clock-o pr-2",
      path: "/history",
    },
    {
      name: "Settings",
      active: false,
      icon: "fa fa-gear pr-2",
      path: "/settings",
    },
    {
      name: "Logout",
      active: false,
      icon: "fa fa-sign-out pr-2",
      path: "/logout",
    },
  ];
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [networks, setNetworks] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [contactDataBundles, setContactDataBundles] = useState([]);
  const [groupDataBundles, setGroupDataBundles] = useState([]);
  const [quickDatabundles, setQuickDataBundles] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedContact, setSelectedContact] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [contactGroupsDataPlan, setContactGroupsDataPlan] = useState("");
  const [contactDataPlan, setContactDataPlan] = useState("");
  const [quickName, setQuickName] = useState("");
  const [quickPhoneNumber, setQuickPhoneNumber] = useState("");
  const [quickDataBundle, setQuickDataBundle] = useState("");
  const [alertDetails, setAlertDetails] = useState([]);
  const [success, setSuccess] = useState(false);
  const [quickNetwork, setQuickNetwork] = useState("");
  const [contactNetwork, setContactNetwork] = useState("");
  const [groupNetwork, setGroupNetwork] = useState("");

  const history = useHistory();

  const headers = {
    "auth-token": localStorage.getItem("sudha-auth-token"),
  };

  useEffect(() => {
    setLoading(true);
    getDataBundles();
    console.log("called me");
  }, [loadMore]);

  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };
  const getDataBundles = () => {
    axios
      .get(`https://api.betarecharge.net/contacts/allContacts`, { headers })
      .then((response) => {
        setContacts(response.data.data);
        setLoading(false);
        // openNotificationWithIcon(
        //   "success",
        //   "Contacts Fetched Successfully",
        //   "All contacts can be found on the table below"
        // );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("sudha-auth-token");
          history.push("/login");
        } else {
          setTimeout(() => {
            setLoading(false);
            openNotificationWithIcon(
              "error",
              "Contacts couldnt be fetched",
              "Contacts fetching failed"
            );
          }, 2000);
        }
      });
    axios
      .get(`https://api.betarecharge.net/groups/`, { headers })
      .then((response) => {
        console.log(response);
        setGroups(response.data.data.contactGroups);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });

    axios
      .get(`https://api.betarecharge.net/networks/`, { headers })
      .then((response) => {
        setNetworks(response.data.data);

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  };

  const contactFilterBundle = (network_id) => {
    axios
      .get(`https://api.betarecharge.net/bundles/network/${network_id}`, { headers })
      .then((response) => {
        console.log(response);
        setContactDataBundles(response.data.data);

        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("sudha-auth-token");
          history.push("/login");
        } else {
          setTimeout(() => {
            setLoading(false);
            openNotificationWithIcon(
              "error",
              "Contacts couldnt be fetched",
              "Contacts fetching failed"
            );
          }, 2000);
        }
      });
  };

  const quickFilterBundle = (network_id) => {
    axios
      .get(`https://api.betarecharge.net/bundles/network/${network_id}`, { headers })
      .then((response) => {
        console.log(response);
        setQuickDataBundles(response.data.data);

        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("sudha-auth-token");
          history.push("/login");
        } else {
          setTimeout(() => {
            setLoading(false);
            openNotificationWithIcon(
              "error",
              "Contacts couldnt be fetched",
              "Contacts fetching failed"
            );
          }, 2000);
        }
      });
  };

  const groupFilterBundle = (network_id) => {
    axios
      .get(`https://api.betarecharge.net/bundles/network/${network_id}`, { headers })
      .then((response) => {
        console.log(response);
        setGroupDataBundles(response.data.data);

        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("sudha-auth-token");
          history.push("/login");
        } else {
          setTimeout(() => {
            setLoading(false);
            openNotificationWithIcon(
              "error",
              "Contacts couldnt be fetched",
              "Contacts fetching failed"
            );
          }, 2000);
        }
      });
  };

  const handleContactDataRecharge = (e) => {
    e.preventDefault();
    let data = {
      dataPlan: contactDataPlan,
      contacts: [selectedContact],
    };
    axios
      .post(`https://api.betarecharge.net/contacts/transfer/data`, data, { headers })
      .then((res) => {
        setTimeout(() => {
          setAlertDetails([
            "success",
            "Data Sent",
            "Data has been Sent successfully",
          ]);
          setSuccess(true);
          setContactDataPlan("");
          setSelectedContact("");
          // setLoadMore(!loadMore);
        }, 1000);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("sudha-auth-token");
          history.push("/login");
        } else {
          setTimeout(() => {
            openNotificationWithIcon(
              "error",
              "Data Not Sent",
              error.response.data.message
            );
          }, 1000);
        }
        console.log("error is", error);
      });
  };

  const handleQuickDataRecharge = (e) => {
    e.preventDefault();
    let data = {
      name: contactDataPlan,
      phoneNumber: quickPhoneNumber,
      dataPlan: quickDataBundle,
      network: quickNetwork,
    };
    axios
      .post(`https://api.betarecharge.net/contacts/external/transfer/data`, data, { headers })
      .then((res) => {
        setTimeout(() => {
          setAlertDetails([
            "success",
            "Data Sent",
            "Data has been Sent successfully",
          ]);
          setSuccess(true);
          setContactDataPlan("");
          setSelectedContact("");
          setVisible(false)
          // setLoadMore(!loadMore);
        }, 1000);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("sudha-auth-token");
          history.push("/login");
        } else {
          setTimeout(() => {
            openNotificationWithIcon(
              "error",
              "Data Not Sent",
              error.response.data.message
            );
          }, 1000);
        }
        console.log("error is", error);
      });
  };

  const handleGroupDataRecharge = (e) => {
    e.preventDefault();
    let data = {
      group: selectedGroup,
      dataPlan: contactGroupsDataPlan,
    };
    axios
      .post(
        `https://api.betarecharge.net/contacts/external/transfer/data
      `,
        data,
        { headers }
      )
      .then((res) => {
        setTimeout(() => {
          setAlertDetails([
            "success",
            "Data Sent",
            "Data has been Sent successfully",
          ]);
          setSuccess(true);
          setContactGroupsDataPlan("");
          setSelectedGroup("");
          // setLoadMore(!loadMore);
        }, 1000);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("sudha-auth-token");
          history.push("/login");
        } else {
          setTimeout(() => {
            openNotificationWithIcon(
              "error",
              "Data Not Sent",
              error.response.data.message
            );
          }, 1000);
        }
        console.log("error is", error);
      });
  };
  const showAlert = () => {
    return (
      <SweetAlert
        type={alertDetails[0]}
        confirmBtnText="Cool"
        show={success}
        title={alertDetails[1]}
        text={alertDetails[2]}
        onConfirm={() => setSuccess(false)}
      />
    );
  };

  return (
    <div>
      <Layout sidedata={sidebarData}>
        {success && showAlert()}

        <Modal
          title="Quick Buy Data"
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <form onSubmit={handleQuickDataRecharge}>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              required
              value={quickName}
              onChange={(e) => setQuickName(e.target.value)}
            />
            <br />
            <input
              type="number"
              className="form-control"
              placeholder="Phone Number"
              required
              value={quickPhoneNumber}
              onChange={(e) => setQuickPhoneNumber(e.target.value)}
            />
            <br />
            <select
              class="form-control"
              id="exampleFormControlSelect1"
              required
              value={quickNetwork}
              onChange={(e) => {
                setQuickNetwork(e.target.value);
                quickFilterBundle(e.target.value);
              }}
            >
              <option value="">-- Network --</option>
              {networks && networks.map((network, index) => {
                return (
                  <option key={network._id} value={network._id}>
                    {network.name}
                  </option>
                );
              })}
            </select>
            <br />
            <select
              class="form-control"
              id="exampleFormControlSelect1"
              required
              value={quickDataBundle}
              onChange={(e) => {
                setQuickDataBundle(e.target.value);
              }}
            >
              <option value="">-- Select Data Bundle --</option>
              {quickDatabundles && quickDatabundles.map((data, index) => {
                return (
                  <option key={data._id} value={data._id}>
                    {data.network.name} {data.description} ({data.validityValue}{" "}
                    {data.validity.name})
                  </option>
                );
              })}
            </select>

            <br />
            <div className="text-right">
              <button type="submit" className="btn btn-primary px-4 bg-orange">
                Purchase
              </button>
            </div>
          </form>
        </Modal>
        <div className=" bg-cream full-height">
          <div className="justify-content-between d-flex">
            <div className="">
              <h3 className="font-weight-bold orange pt-1">Data</h3>
              <p>All about Data purchase</p>
            </div>
            <div className="">
              <i className="fa fa-bell fa-2x px-2 "></i>
              <i className="fa fa-comment fa-2x p-2"></i>
              <img
                src={localStorage.getItem("sudha_user_avatar")}
                className=" rounded-circle px-2"
                height="40px"
                alt=""
              />
            </div>
          </div>
          <div className="">
            <button
              className="btn btn-primary font-weight-bold p-3 rounded-pill bg-orange "
              onClick={() => setVisible(true)}
            >
              Quick Data Purchase
            </button>

            <div className="mt-4 ">
              <div className="">
                <h4>Send Data To Contacts</h4>
                <form action="mt-4 " onSubmit={handleContactDataRecharge}>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label
                          className="font-weight-bold"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Select Network
                        </label>
                        <select
                          className="form-control form-control-lg"
                          id="exampleFormControlSelect1"
                          required
                          value={contactNetwork}
                          onChange={(e) => {
                            setContactNetwork(e.target.value);
                            contactFilterBundle(e.target.value);
                          }}
                        >
                          <option value="">-- Select Network --</option>
                          {networks && networks.map((network, index) => {
                            return (
                              <option key={network._id} value={network._id}>
                                {network.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label
                          className="font-weight-bold"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Select Data Plan
                        </label>
                        <select
                          className="form-control form-control-lg"
                          id="exampleFormControlSelect1"
                          required
                          value={contactDataPlan}
                          onChange={(e) => setContactDataPlan(e.target.value)}
                        >
                          <option value="">-- Select Data Bundle --</option>
                          {contactDataBundles && contactDataBundles.map((data, index) => {
                            return (
                              <option key={data._id} value={data._id}>
                                {data.network.name} {data.description} (
                                {data.validityValue} {data.validity.name})
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="font-weight-bold"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Select Your Contact
                    </label>
                    <select
                      className="form-control form-control-lg"
                      id="exampleFormControlSelect1"
                      required
                      value={selectedContact}
                      onChange={(e) => setSelectedContact(e.target.value)}
                    >
                      <option value="">-- Select Contact --</option>
                      {contacts && contacts.map((contact, index) => {
                        return (
                          <option key={contact._id} value={contact._id}>
                            {contact.name} ({contact.phoneNumber})
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <button className="btn btn-primary font-weight-bold px-5 py-2 bg-orange text-right pull-right ">
                    Send
                  </button>
                </form>
              </div>
              {/* send to Groups */}
              <br />
              <div className="mt-5">
                <h4>Send Data To Groups</h4>
                <form action="mt-4 " onSubmit={handleGroupDataRecharge}>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label
                          className="font-weight-bold"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Select Network
                        </label>
                        <select
                          className="form-control form-control-lg"
                          id="exampleFormControlSelect1"
                          required
                          value={groupNetwork}
                          onChange={(e) => {
                            setGroupNetwork(e.target.value);
                            groupFilterBundle(e.target.value);
                          }}
                        >
                          <option value="">-- Select Network --</option>
                          {networks && networks.map((network, index) => {
                            return (
                              <option key={network._id} value={network._id}>
                                {network.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label
                          className="font-weight-bold"
                          htmlFor="exampleFormControlSelect1"
                        >
                          Select Data Plan
                        </label>
                        <select
                          className="form-control form-control-lg"
                          id="exampleFormControlSelect1"
                          required
                          value={contactGroupsDataPlan}
                          onChange={(e) =>
                            setContactGroupsDataPlan(e.target.value)
                          }
                        >
                          <option value="">-- Select Data Bundle --</option>
                          {groupDataBundles && groupDataBundles.map((data, index) => {
                            return (
                              <option key={data._id} value={data._id}>
                                {data.network.name} {data.description} (
                                {data.validityValue} {data.validity.name})
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="font-weight-bold"
                      htmlFor="exampleFormControlSelect1"
                    >
                      Select Group
                    </label>
                    <select
                      className="form-control form-control-lg"
                      id="exampleFormControlSelect1"
                      required
                      value={selectedGroup}
                      onChange={(e) => setSelectedGroup(e.target.value)}
                    >
                      <option value="">--Select Group--</option>
                      {groups && groups.map((group, index) => {
                        return (
                          <option key={group._id} value={group._id}>
                            {group.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <button className="btn btn-primary font-weight-bold px-5 py-2 bg-orange text-right pull-right ">
                    Send
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
