import React, { useState, useEffect } from "react";
import "./Auth.css";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";

export default function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [captchaStatus, setCaptchaStatus] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("sudha-auth-token")) {
      history.push("/dashboard");
      history.push("/dashboard");
      history.push("/dashboard");
      history.push("/dashboard");
    }
  }, [])

  const handleRegister = (e) => {
    e.preventDefault();
    let data = {
      name: name,
      email: email,
      password: password,
      phoneNumber: phoneNumber,
    };
    axios
      .post(`https://api.betarecharge.net/register/`, data)
      .then((res) => {
        console.log(res.data.message);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          history.push("/login");
        }, 2000);
      })
      .catch((error) => {
        setError(true);
        setErrorMessage("An Error Occoured");
        setTimeout(() => {
          setError(false);
        }, 2000);
        console.log("error is", error);
      });
  };

  return (
    <div className="">
      <div id="register-bg" className="fullpage">
      <div className="container-fluid">
      <h2 className="display-4 font-weight-bold text-white pt-2 pl-2">
          Sudha'
        </h2>
        <div className="row">
          <div className="col-md-6  ">
            <h1 className="text-left mr-5 font-weight-bold mt-3 text-white">
              CREATE AN ACOUNT!
            </h1>
            <p className="text-white ">
              Register And Get Started With Us Today
            </p>
            <form className="" onSubmit={handleRegister}>
              {error && (
                <div className="alert alert-danger">{errorMessage}</div>
              )}
              {success && (
                <div className="alert alert-success">Registered Successfully ... Please login</div>
              )}
              <div className="form-group">
                <input
                  type="text"
                  className="form-control p-4"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group mt-5">
                <input
                  type="number"
                  className="form-control p-4"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="form-group mt-5">
                <input
                  type="email"
                  className="form-control p-4"
                  placeholder="Email "
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group mt-5">
                <input
                  type="password"
                  className="form-control p-4"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="justify-content-start d-flex mt-5">
                <button
                  type="submit"
                  className="btn btn-primary rounded px-5 py-3 bg-orange font-weight-bolder"
                  style={{ fontSize: "30px" }}
                >
                  SIGNUP
                </button>
              </div>
            </form>
          </div>
          <div className="col-md-6"></div>
        </div>
        <br />
        <br />
        <br />
        <br />

        <div className=" ">
          <div className="px-3 text-right">
            <h4 className="purple">
              Already Have An Account ?{" "}
              <span className="orange font-weight-bold"><Link className={"orange"} to="/login">LOGIN</Link></span>
            </h4>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}
