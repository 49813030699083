import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../App";
import logo from "../../assets/logo.png";
import "./Landing.css";

export default function Landing() {
  const isAuthenticated = useContext(AuthContext);

  return (
    <div className="landing-main full-height">
      <nav className="navbar navbar-expand-lg navbar-light ">
        <a className="navbar-brand" href="#">
          <img src={logo} width={120} height={100} alt loading="lazy" />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse " id="navbarSupportedContent">
          <ul className="navbar-nav  ml-auto">
            {/* <li className="nav-item ">
              <a className="nav-link" href="#">
                AIRTIME
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                DATA
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link "
                href="#"
                tabIndex={-1}
                aria-disabled="true"
              >
                TEAMS
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                SUPPORT
              </a>
            </li> */}
            <li className=" nav-item active font-weight-bold ">
              <Link to="/login">
                <a className="nav-link">LOGIN</a>
              </Link>
            </li>
            <div className="">
              <Link to="/register">
                <a className="btn btn-primary px-4 bg-orange">SIGNUP</a>
              </Link>
            </div>
          </ul>
        </div>
      </nav>

      <div className="container-fluid ">
        <div className="small text-center center ">
        <div className=" text-center landing-side-img-sm img-fluid">
        </div>
          <div className="text-center center">
            <h1 className="text-lead"><i>Send Airtime and data to your team . Anytime.
              <br/>
            <span>Anywhere</span></i></h1>
            <div className=" ">
              {localStorage.getItem("sudha-auth-token") ? (
                <Link to="/dashboard">
                  <div
                    className="btn btn-primary rounded px-5 py-3 bg-orange font-weight-bolder"
                    style={{ fontSize: "30px" }}
                  >
                    Get Started
                  </div>
                </Link>
              ) : (
                <Link to="/login">
                  <div
                    className="btn btn-primary rounded px-5 py-3 bg-orange font-weight-bolder"
                    style={{ fontSize: "30px" }}
                  >
                    Get Started
                  </div>
                </Link>
              )}
              
            </div>
          </div>
        </div>
        <div className="row big">
        <div className="col-md-6 text-center landing-side-img">
        </div>
          
          <div className="col-md-1"></div>
          <div className="col-md-4 mt-5 pt-5 ">
            <h1 className="text-lead pt-4"><i>Send Airtime and data to your team . Anytime.
              <br/>
            <span>Anywhere</span></i></h1>
            <div className="justify-content-start d-flex mt-5">
              {localStorage.getItem("sudha-auth-token") ? (
                <Link to="/dashboard">
                  <div
                    className="btn btn-primary rounded px-5 py-3 bg-orange font-weight-bolder"
                    style={{ fontSize: "30px" }}
                  >
                    Get Started
                  </div>
                </Link>
              ) : (
                <Link to="/login">
                  <div
                    className="btn btn-primary rounded px-5 py-3 bg-orange font-weight-bolder"
                    style={{ fontSize: "30px" }}
                  >
                    Get Started
                  </div>
                </Link>
              )}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
