import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/Layout/Layout";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Modal, Table, notification, Checkbox } from "antd";
import Moment from "moment";
import SweetAlert from "sweetalert2-react";

export default function Contacts() {
  const sidebarData = [
    {
      name: "dashboard",
      active: false,
      icon: "fa fa-home",
      path: "/dashboard",
    },
    {
      name: "Add Funds",
      active: false,
      icon: "fa fa-credit-card pr-2",
      path: "/add-funds",
    },
    {
      name: "Contact",
      active: true,
      icon: "fa fa-user pr-2",
      path: "/contacts",
    },
    {
      name: "Airtime",
      active: false,
      icon: "fa fa-phone pr-2",
      path: "/airtime",
    },
    {
      name: "Data",
      active: false,
      icon: "fa fa-volume-control-phone pr-2",
      path: "/data",
    },
    {
      name: "History",
      active: false,
      icon: "fa fa-clock-o pr-2",
      path: "/history",
    },
    {
      name: "Settings",
      active: false,
      icon: "fa fa-gear pr-2",
      path: "/settings",
    },
    {
      name: "Logout",
      active: false,
      icon: "fa fa-sign-out pr-2",
      path: "/logout",
    },
  ];
  const [loading, setLoading] = useState(false);
  const [groupVisible, setGroupVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [spreadsheetVisible, setSpreadsheetVisible] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [allContacts, setAllContacts] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const [contactView, setContactView] = useState(true);
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactNetwork, setContactNetwork] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [page, setPage] = useState("1");
  const [pageSize, setPageSize] = useState("");
  const [pageNumber, setPageNumber] = useState("");
  const history = useHistory();
  const [contactSpreadsheet, setContactSpreadsheet] = useState("");
  const file_ref = useRef();
  const [airtimeContacts, setairtimeContacts] = useState([]);
  const [amount, setAmount] = useState(0);
  const [airtimeGroupVisible, setAirtimeGroupVisible] = useState(false);
  // edit stuffs
  const [editContactVisible, setEditContactVisible] = useState(false);
  const [selectedContact, setSelectedContact] = useState("");
  const [editContactName, setEditContactName] = useState("");
  const [editContactPhone, setEditContactPhone] = useState("");
  const [editContactNetwork, setEditContactNetwork] = useState("");
  const [editContactNetworkName, setEditContactNetworkName] = useState("");

  const [alertDetails, setAlertDetails] = useState([]);
  const [groups, setGroups] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [clearContactModal, setClearContactModal] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState();

  const headers = {
    "auth-token": localStorage.getItem("sudha-auth-token"),
  };

  useEffect(() => {
    setLoading(true);
    getContacts();
    console.log("called me");
  }, [loadMore]);

  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  const getContacts = () => {
    axios
      .get(`https://api.betarecharge.net/contacts?page=${page}`, { headers })
      .then((response) => {
        setContacts(response.data.data.contacts);
        if (response.data.data.pageInfo) {
          setPage(response.data.data.pageInfo.page);
          setPageSize(response.data.data.pageInfo.total_pages);
        }
        setLoading(false);
        // openNotificationWithIcon(
        //   "success",
        //   "Contacts Fetched Successfully",
        //   "All contacts can be found on the table below"
        // );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("sudha-auth-token");
          history.push("/login");
        } else {
          setTimeout(() => {
            setLoading(false);
            openNotificationWithIcon(
              "error",
              "Contacts couldnt be fetched",
              "Contacts fetching failed"
            );
          }, 2000);
        }
      });

      axios
      .get(`https://api.betarecharge.net/contacts/allContacts`, { headers })
      .then((response) => {
        let tempData = response.data.data;
        tempData.map(temp => {
          temp.isChecked = false;
          return temp;
        })
        setAllContacts(tempData);
        // openNotificationWithIcon(
        //   "success",
        //   "Contacts Fetched Successfully",
        //   "All contacts can be found on the table below"
        // );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("sudha-auth-token");
          history.push("/login");
        } else {
          setTimeout(() => {
            setLoading(false);
            openNotificationWithIcon(
              "error",
              "Contacts could not be fetched",
              "Contacts fetching failed"
            );
          }, 2000);
        }
      });

    axios
      .get(`https://api.betarecharge.net/networks/`, { headers })
      .then((response) => {
        setNetworks(response.data.data);

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });

    axios
      .get(`https://api.betarecharge.net/groups/`, { headers })
      .then((response) => {
        console.log(response);
        setGroups(response.data.data.contactGroups);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  };

  const handleContactCreation = (e) => {
    e.preventDefault();
    let data = {
      name: contactName,
      email: contactEmail,
      phone_number: contactPhone,
      network: contactNetwork,
    };
    axios
      .post(`https://api.betarecharge.net/contacts/create`, data, { headers })
      .then((res) => {
        setTimeout(() => {
          setAlertDetails([
            "success",
            "Contact Created",
            "contact has been created successfully",
          ]);
          setSuccess(true);
          // setLoadMore(!loadMore);
        }, 1000);
        setVisible(false);
        setLoadMore(!loadMore);
        setContactEmail("");
        setContactName("");
        setContactPhone("");
        setContactNetwork("");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("sudha-auth-token");
          history.push("/login");
        } else {
          setTimeout(() => {
            openNotificationWithIcon(
              "error",
              "Contact was not created",
              error.response.data.message
            );
          }, 1000);
        }
        console.log("error is", error);
      });
    // console.log(data);
    // setLoadMore(!loadMore);
  };

  const handleContactEdit = (e) => {
    e.preventDefault();
    let data = {
      name: editContactName,
      phone_number: editContactPhone,
      network: editContactNetwork,
    };
    axios
      .patch(`https://api.betarecharge.net/contacts/${selectedContact}`, data, {
        headers,
      })
      .then((res) => {
        setTimeout(() => {
          setAlertDetails([
            "success",
            "Contact Edited ",
            "contact has been  successfully edited",
          ]);
          setSuccess(true);
          // setLoadMore(!loadMore);
        }, 1000);
        setEditContactVisible(false);
        setLoadMore(!loadMore);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("sudha-auth-token");
          history.push("/login");
        } else {
          setTimeout(() => {
            openNotificationWithIcon(
              "error",
              "Contact edit was not successdul",
              error.response.data.message
            );
          }, 1000);
        }
        console.log("error is", error);
      });
    // console.log(data);
    // setLoadMore(!loadMore);
  };

  const handleClearFile = () => {
    file_ref.current.value = "";
    setContactSpreadsheet(null);
  };

  const showAlert = () => {
    return (
      <SweetAlert
        type={alertDetails[0]}
        confirmBtnText="Cool"
        show={success}
        title={alertDetails[1]}
        text={alertDetails[2]}
        onConfirm={() => setSuccess(false)}
      />
    );
  };

  const pushAllContacts = () => {
    // mark checkbox
    console.log('pushing contacts');
    let tempContact = [];
    let tempAirtimeContact = [];
    allContacts.forEach(item => {
      console.log(item);
      item.isChecked = true;
      tempContact.push(item);
      tempAirtimeContact.push(item._id);
      setairtimeContacts(tempAirtimeContact);
      setAllContacts(tempContact);
  });
}
  const buy_data = () => {
    if (airtimeContacts.length > 0) {
      let data = {
        amount: amount,
        contacts: airtimeContacts,
      };
      axios
        .post(`https://api.betarecharge.net/contacts/transfer`, data, { headers })
        .then((res) => {
          console.log(res.data.message);
          setTimeout(() => {
            setAlertDetails([
              "success",
              "Airtime Purchase Successful",
              "Airtime has bought been made successfully",
            ]);
            setSuccess(true);
            // clearData();
            setAmount(0);
          }, 1000);
        })
        .catch((error) => {
          setTimeout(() => {
            setAlertDetails([
              "error",
              "An Error occoured",
              "Airtime purchase operation failed",
            ]);
            setSuccess(true);
          }, 1000);
          // clearData();
        });
      setAirtimeGroupVisible(false);
      setairtimeContacts([]);
    } else {
      setAlertDetails([
        "error",
        "Airtime Purchase Failed",
        "You have not selected any contacts",
      ]);
      setSuccess(true);
    }
  };

  const createGroup = () => {
    if (airtimeContacts.length > 0) {
      let data = {
        name: groupName,
        description: groupDescription,
        contacts: airtimeContacts,
      };
      axios
        .post(`https://api.betarecharge.net/groups/create`, data, { headers })
        .then((res) => {
          console.log(res.data.message);
          setTimeout(() => {
            setAlertDetails([
              "success",
              "Group created Successful",
              "Group has been created successfully",
            ]);
            setSuccess(true);
            // clearData();
            setAmount(0);
          }, 1000);
        })
        .catch((error) => {
          setTimeout(() => {
            setAlertDetails([
              "error",
              "An Error occoured",
              "Group creating operation failed",
            ]);
            setSuccess(true);
          }, 1000);
          // clearData();
        });
      setAirtimeGroupVisible(false);
      setairtimeContacts([]);
    } else {
      setAlertDetails([
        "error",
        "Group Creation Failed",
        "Failed to create group",
      ]);
      setSuccess(true);
    }
    setLoadMore(!loadMore);
  };

  const upload_contacts_spreadsheet = () => {
    let data = new FormData();
    data.append("file", contactSpreadsheet);
    console.log(contactSpreadsheet);
    axios
      .post("https://api.betarecharge.net/contacts/upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": localStorage.getItem("sudha-auth-token"),
        },
      })
      .then((response) => {
        console.log(response);
        openNotificationWithIcon(
          "success",
          "contact Spreadsheet Successfully",
          "contact Spreadsheet has been successfully Uploaded"
        );
      })
      .catch((error) => {
        console.log(error.response);
        openNotificationWithIcon(
          "error",
          "contact Spreadsheet Upload Failed",
          error.response.data.message
        );
      });
    handleClearFile();
  };

  const handleDelete = () => {
    axios
    .delete(`https://api.betarecharge.net/contacts/${selectedContactId}`, { headers })
    .then((res) => {
      console.log(res.data.message);
      setTimeout(() => {
        setAlertDetails([
          "success",
          "Contact Deleted Successful",
          "Contact has been deleted successfully",
        ]);
        setSuccess(true);
        setDeleteModal(false);
        // setAmount(0);
        setLoadMore(!loadMore);
      }, 1000);
    })
    .catch((error) => {
      setTimeout(() => {
        setAlertDetails([
          "error",
          "An Error occurred",
          "Deleting operation failed",
        ]);
        setSuccess(true);
      }, 1000);
      // clearData();
    });
  // setAirtimeGroupVisible(false);
  // setairtimeContacts([]);
} 

const handleMultipleDelete = () => {
  axios
  .delete(`https://api.betarecharge.net/contacts/all-contacts`, { headers })
  .then((res) => {
    console.log(res.data.message);
    setTimeout(() => {
      setAlertDetails([
        "success",
        "All contacts have been cleared",
        "All contacts has been deleted successfully",
      ]);
      setSuccess(true);
      setClearContactModal(false);
      // setAmount(0);
      setLoadMore(!loadMore);
    }, 1000);
  })
  .catch((error) => {
    setTimeout(() => {
      setAlertDetails([
        "error",
        "An Error occurred",
        "Deleting operation failed",
      ]);
      setSuccess(true);
    }, 1000);
    // clearData();
  });
// setAirtimeGroupVisible(false);
// setairtimeContacts([]);
} 

  return (
    <div className=" bg-cream">
      <Layout sidedata={sidebarData}>

      <Modal
          title="Delete"
          visible={deleteModal}
          onOk={() => setDeleteModal(false)}
          onCancel={() => setDeleteModal(false)}
          footer={null}
        >
          <div className="">
            <h6 className="font-weight-bold">Are you sure You want to Delete ??</h6>
            <div className="text-right">
              <button className="btn btn-danger btn-sm" onClick={handleDelete}> Delete </button>
            </div>
          </div>
        </Modal>

        <Modal
          title="Clear All Contacts"
          visible={clearContactModal}
          onOk={() => setClearContactModal(false)}
          onCancel={() => setClearContactModal(false)}
          footer={null}
        >
          <div className="">
            <h6 className="font-weight-bold">Are you sure You want to clear all contacts ?</h6>
            <div className="text-right">
              <button className="btn btn-danger btn-sm" onClick={handleMultipleDelete}> Delete </button>
            </div>
          </div>
        </Modal>

        {success && showAlert()}
        <Modal
          title="Add Contact"
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <form onSubmit={handleContactCreation}>
            <div className="form-row">
              <div className="form-group col-md-6 ">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={contactName}
                  onChange={(e) => setContactName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="number"
                  className="form-control"
                  id="phone"
                  value={contactPhone}
                  onChange={(e) => setContactPhone(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label for="inputState">Network</label>
                <select
                  id="inputState"
                  className="form-control"
                  value={contactNetwork}
                  onChange={(e) => setContactNetwork(e.target.value)}
                  required
                >
                  <option value="">...Choose Network...</option>
                  {networks.map((network) => {
                    if (network.airtimeEnable) {
                      return (
                        <option key={network._id} value={network._id}>
                          {network.name}
                        </option>
                      );
                    }
                    // return <option value={network._id}>{network.name}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="text-right">
              <button type="submit" className="btn btn-primary px-4 bg-orange">
                Done
              </button>
            </div>
          </form>
        </Modal>
        <Modal
          title="Upload Spreadsheet"
          visible={spreadsheetVisible}
          onOk={() => setSpreadsheetVisible(false)}
          onCancel={() => setSpreadsheetVisible(false)}
          footer={null}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              upload_contacts_spreadsheet();
            }}
          >
            {/* <h4 className="text-capitalize">spreadsheet upload here</h4> */}
            <input
              type="file"
              className="form-control-file"
              accept=".xls, .xlsx"
              ref={file_ref}
              onChange={(event) => setContactSpreadsheet(event.target.files[0])}
              aria-describedby="fileHelpId"
            />
            <small id="fileHelpId" className="form-text text-muted">
              upload contacts spreadsheet Here
            </small>
            <div className="text-right">
              <button type="submit" className="btn btn-primary px-4 bg-orange">
                Upload
              </button>
            </div>
          </form>
        </Modal>
        <Modal
          title="Buy Airtime"
          visible={airtimeGroupVisible}
          onOk={() => setAirtimeGroupVisible(false)}
          onCancel={() => setAirtimeGroupVisible(false)}
          footer={null}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              buy_data();
            }}
          >
            {/* <h4 className="text-capitalize">spreadsheet upload here</h4> */}
            <input
              type="text"
              className="form-control"
              onChange={(e) => setAmount(e.target.value)}
              placeholder={"200"}
              required
            />
            <div className="text-right mt-2">
              <button type="submit" className="btn btn-primary px-4 bg-orange">
                Proceed
              </button>
            </div>
          </form>
        </Modal>
        <Modal
          title="Edit Contact Modal"
          visible={editContactVisible}
          onOk={() => setEditContactVisible(false)}
          onCancel={() => setEditContactVisible(false)}
          footer={null}
        >
          <form onSubmit={handleContactEdit}>
            {/* <h4 className="text-capitalize">spreadsheet upload here</h4> */}
            <label htmlFor="">Contact Name</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setEditContactName(e.target.value)}
              value={editContactName}
              required
            />
            <br />
            <label htmlFor="">Contact Phone Number</label>
            <input
              type="number"
              className="form-control"
              onChange={(e) => setEditContactPhone(e.target.value)}
              value={editContactPhone}
              required
            />
            <br />
            <div className="form-group ">
              <label for="inputState">Contact Network</label>
              <select
                id="inputState"
                className="form-control"
                value={editContactNetwork}
                onChange={(e) => setEditContactNetwork(e.target.value)}
                required
              >
                <option value="">{editContactNetworkName}</option>
                {networks.map((network) => {
                  if (network.airtimeEnable) {
                    return (
                      <option key={network._id} value={network._id}>
                        {network.name}
                      </option>
                    );
                  }
                  // return <option value={network._id}>{network.name}</option>;
                })}
              </select>
            </div>
            <div className="text-right mt-2">
              <button type="submit" className="btn btn-primary px-4 bg-orange">
                Edit Contact
              </button>
            </div>
          </form>
        </Modal>
        <div className=" bg-cream full-height">
          <div className="justify-content-between d-flex mt-2">
            <div className=" mb-3 ">
              <h2 className="orange font-weight-bold ">Contacts</h2>
              <h4>Manage your team</h4>
            </div>
            <div className="">
              <i className="fa fa-bell fa-2x"></i>
              <i className="fa fa-comment fa-2x"></i>
              <img
                src={localStorage.getItem("sudha_user_avatar")}
                className=" rounded-circle px-2"
                height="40px"
                alt=""
              />
            </div>
          </div>
          <div className="mb-3">
            <div className="justify-content-start d-flex">
              <div className="pr-5" onClick={() => setContactView(true)}>
                {contactView ? (
                  <h2 className="purple ">
                    <u>Contacts</u>
                  </h2>
                ) : (
                  <h2 className="text-secondary ">Contacts</h2>
                )}
              </div>
              <div className="" onClick={() => setContactView(false)}>
                {!contactView ? (
                  <h2 className="purple ">
                    <u>Groups</u>
                  </h2>
                ) : (
                  <h2 className="text-secondary ">Groups</h2>
                )}
              </div>
            </div>
          </div>
          {contactView ? (
            <div>
              <div className="justify-content-between d-flex">
                <div className="">
                  <button
                    className="btn btn-primary m-2 transactions-bg px-3 border border-0"
                    onClick={() => setVisible(true)}
                  >
                    Add Contact <i class="fa fa-user-plus"></i>
                  </button>
                  <button
                    className="btn btn-primary transactions-bg border border-0 "
                    onClick={() => setSpreadsheetVisible(true)}
                  >
                    Upload Spreadsheet <i className="fa fa-upload"></i>
                  </button>
                </div>
                <Link
                  className="btn btn-outline-primary"
                  to="./sudha_contacts_upload_sample.xlsx"
                  download
                  target="_blank"
                >
                  Download Sample Spreadsheet
                  <i className="fa fa-download"></i>
                </Link>
              </div>

              <div className="table-section p-3">
                <table className="table table-striped ">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Network</th>
                      <th scope="col">Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contacts !== undefined ? (
                      contacts.map((item, index) => {
                        // setPage()
                        // setPageSize(item)
                        // setPageNumber()
                        return (
                          <tr key={index}>
                            <td className="text-capitalize font-weight-bold">
                              {item.name}
                            </td>
                            <td>{item.phone_number}</td>
                            <td>{item.networkLabel? item.networkLabel: item.network.name}</td>
                            <td>
                              {Moment(item.created_at).format("YYYY-MM-DD")}{" "}
                            </td>
                            <td>
                              {/* Default dropright button */}
                              <div className="btn-group dropleft">
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary bg-cream dropdown-toggle btn-sm "
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fa fa-cogs purple"></i>
                                </button>
                                <div className="dropdown-menu">
                                  {/* Dropdown menu links */}
                                  <div>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() => {
                                        setEditContactVisible(true);
                                        setEditContactPhone(item.phone_number);
                                        setEditContactName(item.name);
                                        setEditContactNetwork(item.network._id);
                                        setEditContactNetworkName(
                                          item.network.name
                                        );
                                        setSelectedContact(item.id);
                                      }}
                                    >
                                      Edit
                                    </a>
                                    <a 
                                    className="dropdown-item" 
                                    href="#"
                                    onClick={() => {
                                      setSelectedContactId(item.id);
                                      setDeleteModal(true);
                                      // setDeleteContactVisible(true);
                                      // setSelectedContact(item.id);
                                    }}
                                    >
                                      Delete
                                    </a>
                                    {/* <a className="dropdown-item" href="#">Something else here</a> */}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan={"7"}>No Data To Display</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* pagination here */}
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    <li
                      className={
                        page == "1" ? "page-item disabled" : "page-item"
                      }
                      onClick={() => {
                        setPage(parseInt(page) - 1);
                        setLoadMore(!loadMore);
                      }}
                    >
                      <a
                        className="page-link"
                        href="#"
                        tabIndex={-1}
                        aria-disabled="true"
                      >
                        Previous
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        {page}
                      </a>
                    </li>
                    <li
                      className={
                        page === pageSize ? "page-item disabled" : "page-item"
                      }
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => {
                          setPage(parseInt(page) + 1);
                          setLoadMore(!loadMore);
                        }}
                      >
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          ) : (
            <div>
              <div className="justify-content-between d-flex">
                <Modal
                  title="Create Group"
                  visible={groupVisible}
                  onOk={() => setGroupVisible(false)}
                  onCancel={() => setGroupVisible(false)}
                  footer={null}
                >
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      createGroup();
                      setGroupVisible(false);
                      setairtimeContacts([]);
                    }}
                  >
                    <h4>Contacts Selected ({airtimeContacts.length})</h4>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Contact Group name"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Contact Group Description"
                        rows={4}
                        value={groupDescription}
                        onChange={(e) => setGroupDescription(e.target.value)}
                      />
                    </div>
                    <div className="text-right">
                      {airtimeContacts.length > 0 && (
                        <button
                          type="submit"
                          className="btn btn-primary px-4 bg-orange"
                        >
                          Create Group
                        </button>
                      )}
                    </div>
                  </form>
                </Modal>
                <div className="d-flex justify-content-between">
                  <div className="">
                    <button
                      className="btn btn-primary m-2 transactions-bg px-3 border border-0"
                      onClick={() => setGroupVisible(true)}
                    >
                      <i class="fa fa-users"></i> Create Group
                    </button>
                  </div>
                  <div className="text-right">
                    <button
                      className="btn m-2 btn-primary transactions-bg border border-0 "
                      onClick={() => setAirtimeGroupVisible(true)}
                    >
                      Recharge Selected Contacts
                      <i className="pl-3 fa fa-upload"></i>
                    </button>
                  </div>
                </div>
                <div className="mr-3">
                  {/* <button className="btn btn-primary " onClick={() => setSpreadsheetVisible(true)}>
                    Download Spreadsheet <i className="fa fa-download"></i>
                  </button> */}
                </div>
              </div>
              <div className="contacts big">
                {/* {JSON.stringify(airtimeContacts)} */}
                <div className="row mt-3">
                  <div className="col-md-3">
                  <button className="btn-primary btn-lg" onClick={() => pushAllContacts()}
                  > 
                    Select All</button>
                    </div>
                    <div className="col-md-6">
                    <button className="btn-danger btn-lg" onClick={() => setClearContactModal(true)}
                  > 
                    Delete
                    </button>
                    </div>
                    </div>
                    <div className="row mt-3">
                  {allContacts.map((unit, index) => {
                    return (
                      <div className="col-1" key={'checkbox-'+ index}>
                        <img
                          src="https://www.w3schools.com/w3images/avatar5.png"
                          height="60px"
                          alt={unit.name}
                          className="rounded-circle img-fluid"
                        />
                        <p className="bg-dark text-white p-2 rounded text-capitalize mt-2">
                          {unit.name}
                        </p>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={unit.isChecked}
                            id={unit._id}
                            key={unit._id}
                            onChange={() => {
                              if (airtimeContacts.includes(unit._id)) {
                                console.log("removed");
                                setairtimeContacts(
                                  airtimeContacts.filter(
                                    (item) => item !== unit._id
                                  )
                                );
                                unit.isChecked = false;
                                // setAllContacts([...allContacts, unit])
                                let tempContacts = allContacts.filter((item) => item._id !== unit._id);
                                setAllContacts([...tempContacts, unit]);
                              } else {
                                setairtimeContacts([
                                  ...airtimeContacts,
                                  unit._id,
                                ]);

                                unit.isChecked = true;
                                let tempContacts = allContacts.filter((item) => item._id !== unit._id);
                                setAllContacts([...tempContacts, unit]);
                              }
                            }}
                          />
                          <label className="form-check-label" htmlFor={unit._id}>
                            Select
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="mt-5">
                <h4>All Contact Groups</h4>
                <table className="table table-striped ">
                  <thead>
                    <tr>
                      <th scope="col">Group Name</th>
                      <th scope="col">Created Date</th>

                      <th scope="col">Contacts Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groups !== undefined ? (
                      groups.map((item, index) => {
                        // setPage()
                        // setPageSize(item)
                        // setPageNumber()
                        return (
                          <tr key={index}>
                            <td className="text-capitalize font-weight-bold">
                              {item.name}
                            </td>
                            <td>
                              {Moment(item.created_at).format("YYYY-MM-DD")}{" "}
                            </td>
                            <td>{item.contacts.length} contacts</td>

                            <td>
                              {/* Default dropright button */}
                              <div className="btn-group dropleft">
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary bg-cream dropdown-toggle btn-sm "
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fa fa-cogs purple"></i>
                                </button>
                                <div className="dropdown-menu">
                                  {/* Dropdown menu links */}
                                  <div>
                                    <a className="dropdown-item" href="#">
                                      View
                                    </a>
                                    {/* <a className="dropdown-item" href="#">
                                      Edit
                                    </a> */}
                                    <a className="dropdown-item" href="#">
                                      Delete
                                    </a>
                                    {/* <a className="dropdown-item" href="#">Something else here</a> */}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan={"7"}>No Data To Display</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </Layout>
    </div>
  );
}
