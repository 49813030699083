import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { Button, Modal, notification } from "antd";
import Moment from "moment";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";

export default function History() {
  const sidebarData = [
    {
      name: "dashboard",
      active: false,
      icon: "fa fa-home",
      path: "/dashboard",
    },
    {
      name: "Add Funds",
      active: false,
      icon: "fa fa-credit-card pr-2",
      path: "/add-funds",
    },
    {
      name: "Contact",
      active: false,
      icon: "fa fa-user pr-2",
      path: "/contacts",
    },
    {
      name: "Airtime",
      active: false,
      icon: "fa fa-phone pr-2",
      path: "/airtime",
    },
    {
      name: "Data",
      active: false,
      icon: "fa fa-volume-control-phone pr-2",
      path: "/data",
    },
    {
      name: "History",
      active: true,
      icon: "fa fa-clock-o pr-2",
      path: "/history",
    },
    {
      name: "Settings",
      active: false,
      icon: "fa fa-gear pr-2",
      path: "/settings",
    },
    {
      name: "Logout",
      active: false,
      icon: "fa fa-sign-out pr-2",
      path: "/logout",
    },
  ];

  const [page, setPage] = useState("1");
  const [pageSize, setPageSize] = useState("");
  const [airtimePage, setairtimePage] = useState("1");
  const [airtimePageSize, setairtimePageSize] = useState("");
  
  const [transactions, setTransactions] = useState([]);
  const [airtimeTransactions, setAirtimeTransactions] = useState([]);
  const [airtimeTransactionsForDownload, setAirtimeTransactionsForDownload] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [topupView, setTopupView] = useState(true);
  const history = useHistory();
  // wahala
  let today = new Date() 
  let tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1)
  let dd = String(tomorrow.getDate()).padStart(2, "0");
  let mm = String(tomorrow.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = tomorrow.getFullYear();
  tomorrow = yyyy + "-" + mm + "-" + dd;
  const [startDate, setStartDate] = useState("2020-11-10");
  const [endDate, setEndDate] = useState(tomorrow);
  // date wahala

  const headers = {
    "auth-token": localStorage.getItem("sudha-auth-token"),
  };

  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  const getTransactions = () => {
    axios
      .get(
        `https://api.betarecharge.net/wallet/history/topup?page=${page}&startDate=${startDate}&endDate=${endDate}`,
        { headers }
      )
      .then((response) => {
        console.log(response.data.data);
        setTransactions(response.data.data.transactions);
        if (response.data.data.pageInfo) {
          setPage(response.data.data.pageInfo.page);
          setPageSize(response.data.data.pageInfo.total_pages);
        }

        // setLoading(false);
        // openNotificationWithIcon(
        //   "success",
        //   "Transactions Fetched Successfully",
        //   "All Transactions can be found in the table below"
        // );
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          localStorage.removeItem("sudha-auth-token");
          history.push("/login");
        } else {
          setTimeout(() => {
            setLoading(false);
            openNotificationWithIcon(
              "error",
              "Transactions couldnt be fetched",
              "Transactions fetching failed"
            );
          }, 3000);
        }
      });
  };

  const getAirtimeTransactions = () => {
    axios
      .get(
        `https://api.betarecharge.net/wallet/history/debit?page=${airtimePage}&startDate=${startDate}&endDate=${endDate}`,
        { headers }
      )
      .then((response) => {
        console.log(response.data.data);
        setAirtimeTransactions(response.data.data.transactions);
        if (response.data.data.pageInfo) {
          setairtimePage(response.data.data.pageInfo.page);
          setairtimePageSize(response.data.data.pageInfo.total_pages);
        }

        // setLoading(false);
        // openNotificationWithIcon(
        //   "success",
        //   "Airtime/Data Transactions Fetched Successfully",
        //   "All Transactions can be found in the table below"
        // );
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          localStorage.removeItem("sudha-auth-token");
          history.push("/login");
        } else {
          setTimeout(() => {
            setLoading(false);
            openNotificationWithIcon(
              "error",
              "Transactions couldnt be fetched",
              "Transactions fetching failed"
            );
          }, 3000);
        }
      });
  };


  const getAirtimeTransactionsForDownload = () => {
    axios
      .get(
        `https://api.betarecharge.net/wallet/history/debit?page=1&size=90000&startDate=${startDate}&endDate=${endDate}`,
        { headers }
      )
      .then((response) => {
        console.log(response.data.data);
        let data = response.data.data.transactions;
        let dataForDownload = [["Phone Number,Amount,Network,Status, transaction ref, Date"]];
        data.forEach((element) => {
          dataForDownload.push([element.phoneNumber, element.amount, element.network, element.status, element.transactionId, element.createdAt]);
        });
        setAirtimeTransactionsForDownload(dataForDownload);
        if (response.data.data.pageInfo) {
          // setairtimePage(response.data.data.pageInfo.page);
          // setairtimePageSize(response.data.data.pageInfo.total_pages);
        }

        // setLoading(false);
        // openNotificationWithIcon(
        //   "success",
        //   "Airtime/Data Transactions Fetched Successfully",
        //   "All Transactions can be found in the table below"
        // );
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          localStorage.removeItem("sudha-auth-token");
          history.push("/login");
        } else {
          setTimeout(() => {
            setLoading(false);
            openNotificationWithIcon(
              "error",
              "Transactions couldnt be fetched",
              "Transactions fetching failed"
            );
          }, 3000);
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    getTransactions();
    getAirtimeTransactions();
    getAirtimeTransactionsForDownload();
    console.log("called me");
  }, [loadMore]);

  return (
    <div>
      <Layout sidedata={sidebarData}>
        <div className=" bg-cream full-height">
          <div className="justify-content-between d-flex">
            <div className="">
              <h3 className="font-weight-bold orange pt-1">History</h3>
              <p>History of all your transactions</p>
            </div>
            <div className="">
              <i className="fa fa-bell fa-2x px-2 "></i>
              <i className="fa fa-comment fa-2x p-2"></i>
              <img
                src={localStorage.getItem("sudha_user_avatar")}
                className=" rounded-circle px-2"
                height="40px"
                alt=""
              />
            </div>
          </div>
          <div className="mb-2">
            <div className="justify-content-start d-flex">
              <div className="pr-5" onClick={() => setTopupView(true)}>
                {topupView ? (
                  <h2 className="purple ">
                    <u>Topup</u>
                  </h2>
                ) : (
                  <h2 className="text-secondary ">Topup</h2>
                )}
              </div>
              <div className="" onClick={() => setTopupView(false)}>
                {!topupView ? (
                  <h2 className="purple ">
                    <u>Airtime/Data</u>
                  </h2>
                ) : (
                  <h2 className="text-secondary ">Airtime/Data</h2>
                )}
              </div>
            </div>
          </div>
          <div className="date-filter">
            <form>
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="">Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Start Date"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      setLoadMore(!loadMore);
                      getAirtimeTransactionsForDownload();
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="">End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="End Date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      setLoadMore(!loadMore);
                      getAirtimeTransactionsForDownload();
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="">
            <Button type="secondary">
          <CSVLink data={airtimeTransactionsForDownload}>Download Report</CSVLink>;
          </Button>

            {topupView ? (
              <div className="table-section p-3">
                <table className="table table-striped table-">
                  <thead>
                    <tr>
                      <th scope="col">ID/Reference</th>
                      <th scope="col">Credit Means</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Type</th>
                      <th scope="col">Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions &&
                      transactions.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-capitalize font-weight-bold">
                              {item._id}
                            </td>
                            <td>{item.creditMeans}</td>
                            <td>{item.amount}</td>
                            <td>{item.type}</td>
                            <td>
                              {Moment(item.createdAt).format(
                                "YYYY-MM-DD hh:mm:ss"
                              )}{" "}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {/* pagination here */}
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    <li
                      className={
                        page == "1" ? "page-item disabled" : "page-item"
                      }
                      onClick={() => {
                        setPage(parseInt(page) - 1);
                        setLoadMore(!loadMore);
                      }}
                    >
                      <a
                        className="page-link"
                        href="#"
                        tabIndex={-1}
                        aria-disabled="true"
                      >
                        Previous
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        {page}
                      </a>
                    </li>
                    <li
                      className={
                        page === pageSize ? "page-item disabled" : "page-item"
                      }
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => {
                          setPage(parseInt(page) + 1);
                          setLoadMore(!loadMore);
                        }}
                      >
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            ) : (
              <div className="table-section p-3">
                <table className="table table-striped table-">
                  <thead>
                    <tr>
                      <th scope="col">ID/Reference</th>
                      <th scope="col">Status</th>
                      <th scope="col">Debit-Means</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Network</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {airtimeTransactions &&
                      airtimeTransactions.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-capitalize font-weight-bold">
                              {item.transactionId}
                            </td>
                            <td>
                              {item.status == "SUCCESSFUL" ? (
                                <span className="badge badge-success p-2">
                                  {item.status}
                                </span>
                              ) : (
                                <span className="badge  p-2 badge-danger">
                                  {item.status}
                                </span>
                              )}
                            </td>
                            <td>{item.debitMeans}</td>
                            <td>{item.amount}</td>
                            <td>{item.network}</td>
                            <td>{item.phoneNumber}</td>
                            <td>
                              {Moment(item.createdAt).format(
                                "YYYY-MM-DD hh:mm:ss"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {/* pagination here */}
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    <li
                      className={
                        airtimePage == "1" ? "page-item disabled" : "page-item"
                      }
                      onClick={() => {
                        setairtimePage(parseInt(airtimePage) - 1);
                        setLoadMore(!loadMore);
                      }}
                    >
                      <a
                        className="page-link"
                        href="#"
                        tabIndex={-1}
                        aria-disabled="true"
                      >
                        Previous
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        {airtimePage}
                      </a>
                    </li>
                    <li
                      className={
                        airtimePage === airtimePageSize
                          ? "page-item disabled"
                          : "page-item"
                      }
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => {
                          setairtimePage(parseInt(airtimePage) + 1);
                          setLoadMore(!loadMore);
                        }}
                      >
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </div>
  );
}
