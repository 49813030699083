import React, { useState, createContext } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Landing from "./pages/Landing/Landing";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ResetPassword from "./pages/Auth/ResetPassword";
import SetNewPassword from "./pages/Auth/SetNewPassword";
import AddFunds from "./pages/AddFunds/AddFunds";
import Contacts from "./pages/Contacts/Contacts";
import Airtime from "./pages/Airtime/Airtime";
import Data from "./pages/Data/Data";
import History from "./pages/History/History";
import Settings from "./pages/Settings/Settings";
import NotFound from "./components/NotFound/NotFound";

export const AuthContext = createContext(false);

function App() {
  const baseUrl = process.env.PUBLIC_URL;

  let isAuth = localStorage.getItem("sudha-auth-token") ? true : false;

  const [isAuthenticated, setIsAuthenticated] = useState(isAuth);

  // A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
  function PrivateRoute({ component: Component, ...rest }, props) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
        localStorage.getItem("sudha-auth-token") ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }

  return (
    <Router basename={baseUrl}>
      <AuthContext.Provider value={isAuthenticated}>
        <Switch>
          <Route path="/" exact component={Landing} />
          <Route path="/login" exact component={Login} />
          <Route path="/register" exact component={Register} />
          <Route path="/reset-password" exact component={ResetPassword} />
          <Route path="/set-new-password/:token" exact component={SetNewPassword} />
          <PrivateRoute path="/dashboard" exact component={Dashboard} />
          <PrivateRoute path="/add-funds" exact component={AddFunds} />
          <PrivateRoute path="/contacts" exact component={Contacts} />
          <PrivateRoute path="/data" exact component={Data} />
          <PrivateRoute path="/airtime" exact component={Airtime} />
          <PrivateRoute path="/history" exact component={History} />
          <PrivateRoute path="/settings" exact component={Settings} />
          <Route component={NotFound} />
        </Switch>
      </AuthContext.Provider>
    </Router>
  );
}

export default App;
